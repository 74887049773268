var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-full p-tb90"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"news-listing"},_vm._l((_vm.data),function(value,index){return _c('div',{key:index,staticClass:"blog-post blog-md date-style-1 clearfix bdr-2 bdr-gray-light m-b60 bg-gray"},[_c('div',{staticClass:"wt-post-media wt-img-effect"},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_c('img',{staticClass:"lazyload__",attrs:{"width":_vm.$getImageSizeByFilterSets('width', 'grid'),"height":_vm.$getImageSizeByFilterSets('height','grid'),"src":_vm.imagePath(value.primaryImage),"alt":value.primaryImage.alt}})])]),_vm._v(" "),_c('div',{staticClass:"wt-post-info p-tb30 text-black"},[_c('div',{staticClass:"wt-post-title"},[_c('h2',{staticClass:"post-title"},[_c('a',{staticClass:"text-black font-20 letter-spacing-4 font-weight-600",attrs:{"href":"javascript:void(0);"}},[_vm._v(" "+_vm._s(value.headline)+" ")])])]),_vm._v(" "),_c('div',{staticClass:"wt-post-meta"},[_c('ul',[_c('li',{staticClass:"post-date"},[_c('strong',[_vm._v(" "+_vm._s(value.datePublished)+" ")])])])]),_vm._v(" "),_c('div',{staticClass:"wt-post-text"},[_c('p',[_vm._v(" "+_vm._s(value.articleResume)+" ")])]),_vm._v(" "),_c('nuxt-link',{staticClass:"v-button letter-spacing-4 font-12 text-uppercase",attrs:{"to":_vm.getLocalizedRoute({
                            name: 'news-category-slug'
                            , params: {
                                category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                                , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                            }
                        })}},[_vm._v("\n                        "+_vm._s(_vm.$t('Lire la suite'))+"\n                    ")])],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }